module.exports = {
  siteMetadata: {
    title: "四维视界",
    author: "@思扬",
    description: "记录与分享",
    siteUrl: "https://fancyoung.com",
    social: [
      {
        name: "github",
        url: "https://github.com/fancyoung",
      },
    ],
  },
  plugins: [
    `gatsby-theme-material-ui`,
    `gatsby-plugin-react-helmet`,
    `gatsby-plugin-sitemap`,
    {
      resolve: `gatsby-source-filesystem`,
      options: {
        name: `images`,
        path: `${__dirname}/src/assets/images`,
      },
    },
    `gatsby-transformer-sharp`,
    `gatsby-plugin-sharp`,
    {
      resolve: `gatsby-plugin-manifest`,
      options: {
        name: `gatsby-starter-default`,
        short_name: `starter`,
        start_url: `/`,
        background_color: `#663399`,
        theme_color: `#663399`,
        display: `minimal-ui`,
        icon: `src/assets/images/favicon.png`, // This path is relative to the root of the site.
      },
    },
    // {
    //   resolve: `gatsby-theme-blog`,
    //   options: {
    //     basePath: `/blog`,
    //     mdxOtherwiseConfigured: true,
    //   },
    // },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "posts",
        path: `${__dirname}/content/posts`,
        // ignore: [`**/.*`], // ignore files starting with a dot
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        name: "cheatsheets",
        path: `${__dirname}/content/cheatsheets`,
        // ignore: [`**/.*`], // ignore files starting with a dot
      },
    },
    {
      resolve: `gatsby-plugin-mdx`,
      options: {
        extensions: [`.md`, `.mdx`],
        defaultLayouts: {
          cheatsheets: require.resolve("./src/components/layout.js"),
          default: require.resolve("./src/components/layout.js"),
        },
        gatsbyRemarkPlugins: [
          {
            resolve: `gatsby-remark-highlight-code`,
            options: {
              terminal: 'carbon',
              theme: 'solarized-dark',
              // lineNumbers: true
            }
          },
        ],
      },
    },
    {
      resolve: 'gatsby-plugin-load-script',
      options: {
        src: '/particles.min.js',
      },
    },
    {
      resolve: `gatsby-plugin-disqus`,
      options: {
        shortname: `fancyoung`
      }
    },
    //},
    // this (optional) plugin enables Progressive Web App + Offline functionality
    // To learn more, visit: https://gatsby.dev/offline
    // `gatsby-plugin-offline`,
  ],
}

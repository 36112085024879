/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"
import Container from "@material-ui/core/Container"

import Header from "./header"
import Footer from "./footer"
import Nav from "../components/nav"

const useStyles = makeStyles((theme) => ({
  main: {
    // backgroundColor: '#F5F5F5',
    // backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}))

const Layout = ({ children }) => {
  const classes = useStyles();
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Container maxWidth="sm">
      <Header siteTitle={data.site.siteMetadata.title} />
      <Nav />
      <main className={classes.main}>{children}</main>
      <Footer></Footer>
    </Container>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

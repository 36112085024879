import React from "react"
import { Link } from "gatsby-theme-material-ui"
import { Container, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

import { siteMetadata } from "../../gatsby-config"

const useStyles = makeStyles((theme) => ({
  footer: {
    // backgroundColor: theme.palette.background.paper,
    // marginTop: theme.spacing(8),
    padding: theme.spacing(6, 0),
  },
}))

const Footer = () => {
  const classes = useStyles()

  return (
    <footer className={classes.footer}>
      <Container maxWidth="lg">
        <Typography variant="subtitle1" align="center" color="textSecondary" component="p">
          © {new Date().getFullYear()}, Built with
          {` `}
          <Link to={siteMetadata.siteUrl}>{siteMetadata.author}</Link>
        </Typography>
      </Container>
    </footer>
  )
}

export default Footer
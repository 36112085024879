import PropTypes from "prop-types"
import React from "react"
import { Typography, Toolbar } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { Link } from "gatsby-theme-material-ui"

const useStyles = makeStyles((theme) => ({
  toolbar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbarTitle: {
    flex: 1,
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    fontFamily: 'Hacked',
  },
}))

const Header = ({ siteTitle }) => {
  const classes = useStyles()

  return (
    <>
      <Toolbar className={classes.toolbar}>
          <Typography
            component="h2"
            variant="h5"
            color="inherit"
            align="center"
            noWrap
            className={classes.toolbarTitle}
          >
        <Link to="/" className={classes.toolbarLink}>
          {siteTitle} fancyoung
        </Link>
          </Typography>
      </Toolbar>
    </>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

import React from "react"
// import { Link, Typography } from '@material-ui/core';
import { Link } from "gatsby-theme-material-ui"
import { Toolbar } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles((theme) => ({
  nav: {
    justifyContent: 'space-between',
    overflowX: 'auto',
  },
  link: {
    padding: theme.spacing(1),
    flexShrink: 0,
  },
}));

const Nav = () => {
  const classes = useStyles()
  const links = [
    { key: 'index', url: '/', title: '首页'},
    { key: 'blog', url: '/blog', title: '博客'},
    { key: 'cheatsheets', url: '/cheatsheets', title: '速查'},
    { key: 'about', url: '/about', title: '关于'}
  ]

  let pathname = ''
  if (typeof window !== `undefined`) {
    // 暂不能用
    pathname = window.location.pathname
  }

  return (
    <nav>
      <Toolbar className={classes.nav}>
        {links.map((item) => (
          <Link noWrap className={classes.link} key={item.key} to={item.url} color={pathname.indexOf(item.url) >= 0 ? "textPrimary" : "initial"}>{item.title}</Link>
        ))}
      </Toolbar>
    </nav>
  )
}

export default Nav